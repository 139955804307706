import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      description
      publishedDate(formatString: "MMMM Do, YYYY")
      body {
        json
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img src={url} alt={alt} title={alt} />
      },
    },
  }

  return (
    <Layout>
      <SEO
        title={props.data.contentfulBlogPost.title}
        description={props.data.contentfulBlogPost.description}
      />
      <div className="blog-page wrapper">
        <div className="container-fluid">
          <h1>{props.data.contentfulBlogPost.title}</h1>
          <p className="desc">{props.data.contentfulBlogPost.description}</p>
          <span className="date">{props.data.contentfulBlogPost.publishedDate}</span>
          {documentToReactComponents(
            props.data.contentfulBlogPost.body.json,
            options
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Blog
